<template>
  <v-menu v-model="menuDate" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateText"
        prepend-icon="mdi-calendar"
        hint="число-місяць-рiк"
        label="Період"
        persistent-hint
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <div class="d-flex">
      <DatePickerPresets @setPresetDate="select" class="d-none d-sm-block" />
      <v-date-picker v-model="dates" @change="select" range first-day-of-week="1">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="clear"> Очистити </v-btn>
        <v-btn text color="primary" @click="menuDate = false"> Закрити </v-btn>
      </v-date-picker>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'DateFilter',

  emits: ['clear'],

  components: {
    DatePickerPresets: () => import('@/components/partials/DatePickerPresets'),
  },

  props: {
    dates: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    menuDate: false,
  }),

  computed: {
    dateText() {
      const formattedDates = this.dates.map((date) => {
        const [year, month, day] = date.split('-')
        return `${day}.${month}.${year}`
      })
      return formattedDates.join(' ﹣ ')
    },
  },

  methods: {
    select(values = []) {
      this.menuDate = false
      if (values.length) this.dates = values
      this.$emit('select', this.dates)
    },
    clear() {
      this.dates = []
      this.$emit('clear')
    },
  },
}
</script>

<style scoped></style>
