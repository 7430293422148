<template>
  <v-container fluid>
    <FinancialReportFilters :filter-defs="filterDefs" @clear="clearFilter" @select="selectFilter" />

    <template>
      <v-tabs v-model="currentReportTab" align-with-title class="blue-grey">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="(item, index) in tabs" :key="index">
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </template>
    <v-tabs-items v-model="currentReportTab">
      <v-tab-item v-for="(item, index) in tabs" :key="index">
        <v-card color="blue-grey lighten-5" flat>
          <component :is="item.component" :currentTab="currentReportTab"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import FinancialReportFilters from '@/components/common/filters/FinancialReportFilters'
import manageFiltersOptions from '@/mixins/manageFiltersOptions'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FinanceInfo',

  props: {
    currentItem: {
      type: Object,
      default: () => null,
    },
  },

  components: {
    FinancialReportFilters,
    Payments: () => import('@/components/finance/financial_statement/Payments'),
    PaymentsTypes: () => import('@/components/finance/financial_statement/PaymentsTypes'),
    PaymentsStatuses: () => import('@/components/finance/financial_statement/PaymentsStatuses'),
    AverageCheck: () => import('@/components/finance/financial_statement/AverageCheck'),
    UniqueClients: () => import('@/components/finance/financial_statement/UniqueClients'),
  },

  mixins: [manageFiltersOptions],

  data() {
    return {
      currentReportTab: null,
      tabs: [
        {
          title: 'Платежі',
          component: 'Payments',
        },
        {
          title: 'Типи платежів',
          component: 'PaymentsTypes',
        },
        {
          title: 'Статуси платежів',
          component: 'PaymentsStatuses',
        },
        {
          title: 'Середній чек',
          component: 'AverageCheck',
        },
        {
          title: 'Кількість унікальних клієнтів',
          component: 'UniqueClients',
        },
      ],
      selectedDates: [],
    }
  },

  computed: {
    ...mapGetters('companies', ['getBalanceHolderFilter', 'getServicePointFilter', 'getTerminalFilter', 'getDeviceFilter']),

    filterDefs() {
      let defs = [...this.defaultFilterDefs]
      defs[0].values = [this.currentItem.balance_holder.id]
      defs[0].show = false
      defs[1].values = [this.currentItem.id]
      defs[1].show = false
      defs.forEach((item) => (item.sizes = { cols: '12', sm: '6', md: '4' }))
      return defs
    },

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  created() {
    this.initialize()
    this.filterDefs[0].applyFunc([this.currentItem.balance_holder.id])
    this.filterDefs[1].applyFunc([this.currentItem.id])
    this.createPayload()
  },

  beforeDestroy() {
    this.SET_STATISTIC_CHART_DATA_PAYMENTS({})
  },

  methods: {
    ...mapActions('companies', ['loadFilter']),
    ...mapMutations('payments', ['SET_STATISTIC_CHART_PAYLOAD', 'SET_STATISTIC_CHART_DATA_PAYMENTS']),

    async initialize() {
      await this.loadFilter(this.currentCompanyId)
    },

    createPayload() {
      const payload = {
        data: {
          'servicePoint.balanceHolder': this.selectedBalanceHolders,
          servicePoint: this.selectedServicePoints,
          terminal: this.selectedTerminals,
          device: this.selectedDevices,
          'device.type': this.selectedDevicesTypes,
          createdAt: {
            fromDate: this.selectedDates[0],
            toDate: this.selectedDates[1],
          },
        },
        companyId: this.currentCompanyId,
      }
      this.SET_STATISTIC_CHART_PAYLOAD(payload)
    },

    selectFilter(id, val) {
      this.filterDefs.find((item) => item.id === id).applyFunc(val)
      this.createPayload()
    },

    clearFilter(id) {
      this.filterDefs.find((item) => item.id === id).applyFunc([])
      this.createPayload()
    },
  },
}
</script>
