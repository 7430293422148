<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-for="filter in filteredFilterDefs"
        :key="filter.id"
        :cols="filter.sizes.cols"
        :sm="filter.sizes.sm"
        :md="filter.sizes.md"
        :lg="filter.sizes.lg"
        :xl="filter.sizes.xl"
      >
        <DateFilter
          v-if="filter.date"
          :dates="dates"
          @clear="$emit('clear')"
          @select="(val) => $emit('select', filter.id, val)"
        />
        <CommonFilter
          v-else
          :disabled="filter.disabled"
          :item-text="filter.options.text"
          :item-value="filter.options.value"
          :label="filter.label"
          :options="filter.options.list"
          :values="filter.values"
          @select="(val) => $emit('select', filter.id, val)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonFilter from '@/components/common/filters/CommonFilter.vue'
import DateFilter from '@/components/common/filters/DateFilter.vue'

export default {
  name: 'FinancialReportFilters',

  components: { DateFilter, CommonFilter },

  events: ['select', 'clear'],

  props: {
    dates: {
      type: Array,
      default: () => [],
    },
    filterDefs: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredFilterDefs() {
      return this.filterDefs.filter((item) => item.show)
    },
  },
}
</script>
